// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Load BS
require('bootstrap');

//Load Libraries
require('flatpickr/dist/flatpickr.min');
// require('magnific-popup/dist/jquery.magnific-popup.min');
require('objectFitPolyfill/dist/objectFitPolyfill.min');
// require('select2/dist/js/select2.full.min');
// require('slick-slider/slick/slick.min');
require('inputmask/dist/jquery.inputmask.min');
// require('dropzone/dist/min/dropzone.min');

//Load Modules
// require('./modules/accordion');
// require('./modules/animatein');
// require('./modules/bannerVideo');
require('./modules/formInputs');
// require('./modules/localVideoBlock');
require('./modules/menu');
require('./modules/modal');
// require('./modules/scrollTo');
// require('./modules/searchToggle');
// require('./modules/shareSocial');
// require('./modules/sideMenu');
// require('./modules/tabs');
// require('./modules/cropper.min');

$('.toggleButton').click(function(){
    $(this).toggleClass('__active');
    if ($(this).attr('aria-expanded') == 'true') {
        $(this).attr('aria-expanded', false);
        $(this).find('.toggleText').text('Open');
        $(this).siblings('.toggleContent').slideUp();
    } else {
        $(this).attr('aria-expanded', true)
        $(this).find('.toggleText').text('Close');
        $(this).siblings('.toggleContent').slideDown();
    }
});

$('.tooltipButton').click(function(){
    if ($(this).attr('aria-expanded') == 'true') {
        $(this).removeClass('__active');
        $(this).attr('aria-expanded', false);
        $(this).siblings('.tooltipContent').fadeOut();
    } else {
        $(this).attr('aria-expanded', true)
        $(this).addClass('__active');
        $(this).siblings('.tooltipContent').fadeIn();
    }
});
